import { theme } from "../../theme";

export type ShimmerProps = {
  text?: string;
  width: string;
  height: string;
  radius: string;
};

export default function Shimmer({ width, height, radius, text }: ShimmerProps) {
  const style = {
    width,
    height,
    borderRadius: radius,
  };

  return (
    <>
      <ShimmerStyle />
      <div style={style} className={"shine"}>
        {text}
      </div>
    </>
  );
}

function ShimmerStyle() {
  return (
    <style>
      {`
          .shine {
              background: ${theme()?.container_main};
              background-image: linear-gradient(to right, ${
                theme()?.container_main
              } 0%, ${theme()?.container_second} 20%, ${
        theme()?.container_main
      } 40%, ${theme()?.container_main} 100%);
              background-repeat: no-repeat;
              background-size: 800px 1000px; 
              display: inline-block;
              position: relative; 
              
              animation-duration: 1s;
              animation-fill-mode: forwards; 
              animation-iteration-count: infinite;
              animation-name: animationShimmer;
              animation-timing-function: linear;
          }
          
          @keyframes animationShimmer {
              0% {
              background-position: -468px 0;
              }
              
              100% {
              background-position: 468px 0; 
              }
          }
          `}
    </style>
  );
}
