//Default
import React, { useEffect, useState } from "react";

//Services
import { deleteNotification } from "../../services/delete";
import { getCompanys, getNotifications } from "../../services/get";
import { createNotify, reprocessNotify } from "../../services/post";

//Functions

//Components
import Input from "../../components/input/input";

//Icons
import { AiOutlineNotification } from "react-icons/ai";

//Images

//Style
import toast from "react-hot-toast";
import {
  MdAdd,
  MdClose,
  MdDelete,
  MdLoop,
  MdOutlineNotifications,
  MdSearchOff,
} from "react-icons/md";
import Button from "../../components/button/buttons";
import Shimmer from "../../components/shimmer/shimmer";
import Sidebar from "../../components/sidebar/sidebar";
import useLocalStorage from "../../hooks/useLocalStorage";
import { theme } from "../../theme";
import "./notification.css";

export function CreateNotify({
  setPopup,
  setReload,
}: {
  setPopup?: any;
  setReload?: any;
}) {
  const [companys, setCompanys] = useState<[] | any>();
  const [title, setTitle] = useState<string>("");
  const [texts, setText] = useState<string>("");
  const [links, setLinks] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("Atualização");
  const [type, setType] = useState<string>("update");
  useEffect(() => {
    async function fetchData() {
      const [companysarr] = await Promise.all([getCompanys()]);
      setCompanys(companysarr.data);
    }
    fetchData();
  }, []);

  async function notifyCreate() {
    const toastId = toast.loading("Criando notificação...");
    if (title?.length == 0 || texts?.length == 0) {
      toast.dismiss(toastId);
      return toast.error("Preencha todos os campos!");
    }
    const _create = await createNotify({
      status: type,
      title: title,
      text: texts,
      links: links,
    });
    if (_create?.code && _create?.code == 200) {
      toast.dismiss(toastId);
      setPopup("");
      setReload(true);
      return toast.success("Notificação Criada!");
    }
    toast.dismiss(toastId);
    return toast.error("Erro ao criar notificação!");
  }

  return (
    <div className="background_create ">
      <div
        className="create_content"
        style={{ background: theme()?.container_main }}
      >
        <div className="top">
          <MdClose
            cursor={"pointer"}
            size={"1.5em"}
            color={theme()?.icon.default}
            onClick={() => {
              setPopup("");
            }}
          />
        </div>
        <div className="title">
          <div
            className="icon_container"
            style={{ background: theme()?.container_second }}
          >
            <MdOutlineNotifications
              size={"1.4em"}
              color={theme()?.icon.default}
            />
          </div>
          <div className="text">
            <h3 style={{ color: theme()?.font.bold }}>Criar Notificação</h3>
          </div>
        </div>
        <div
          className="line"
          style={{ background: theme()?.line_container }}
        ></div>
        <div className="content">
          <div className="input_select">
            <p style={{ color: theme()?.font.bold, marginBottom: "5px" }}>
              Tipo
            </p>
            <select
              style={{
                background: theme()?.container_second,
                color: theme()?.font.bold,
              }}
              onChange={(e) => {
                setStatus(e.target.value);
                if (e.target.value == "Atualização") return setType("update");
                if (e.target.value == "Aviso") return setType("warning");
              }}
              value={status}
              defaultValue={"Atualização"}
            >
              <option>Atualização</option>
              <option>Aviso</option>
            </select>
          </div>
          <Input
            text="Titulo"
            onValue={(value: string) => {
              setTitle(value);
            }}
            value={title}
            placeholder="Digite o titulo da notificação"
          />
          <div className="text_area">
            <p style={{ color: theme()?.font.bold }}>Mensagem</p>
            <textarea
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setText(e.target.value);
              }}
              placeholder="Mensagem"
              style={{
                background: theme()?.container_second,
                borderColor: theme()?.line_container,
                color: theme()?.font.bold,
              }}
            />
          </div>
          <div className="links">
            {links?.map((res: any, i: number) => {
              return (
                <div className="item">
                  <Input
                    onValue={(value: string) => {
                      const link = [...links];
                      link[i] = value;
                      setLinks(link);
                    }}
                    value={res}
                    placeholder="Cole o link"
                  />
                  <div
                    className="delete_icon"
                    style={{ background: theme()?.container_second }}
                    onClick={() => {
                      setLinks((prevstate) => {
                        const copy = JSON.parse(JSON.stringify(prevstate));
                        copy.splice(i, 1);
                        return copy;
                      });
                    }}
                  >
                    <MdDelete color="red" />
                  </div>
                </div>
              );
            })}
            {links.length != 4 && (
              <div
                className="add"
                onClick={() => {
                  setLinks((links) => [...links, ""]);
                }}
              >
                <p style={{ color: theme()?.font.regular }}>Add link</p>
                <MdAdd color={theme()?.font.regular} size={".8em"} />
              </div>
            )}
          </div>
          <div className="buttons">
            <Button
              onTap={() => {
                notifyCreate();
              }}
              text="Enviar notificação"
              backcolor={theme()?.main_color}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Notification() {
  const [initialMenu, setInitialMenu] = useState<[] | any>();
  const [notify, setNotify] = useState<[] | any>([]);
  const [filterNotify, setFilterNotify] = useState<[] | any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [usetheme, setUsetheme] = useLocalStorage(
    "theme",
    window.localStorage.getItem("theme")
  );
  const [search, setSearch] = useState<string>("");
  const [popup, setPopup] = useState<string>("");
  const [text, setText] = useState<string>("");

  useEffect(() => {
    async function fetchData() {
      const [notarr] = await Promise.all([getNotifications()]);
      setNotify(notarr?.data);
      setFilterNotify(notarr?.data);
      setLoading(false);
      setReload(false);
    }
    fetchData();
  }, [reload]);

  useEffect(() => {
    setInitialMenu([
      {
        title: "Notificações",
        icon: (
          <MdOutlineNotifications size={"2em"} color={theme()?.icon.default} />
        ),
        value: notify?.length,
      },
    ]);
  }, [notify, usetheme]);

  async function notificationDelete(id: string) {
    const toastId = toast.loading("Deletando notificação...");
    const _delete = await deleteNotification(id);
    if (_delete?.code && _delete?.code == 200) {
      toast.dismiss(toastId);
      setReload(true);
      return toast.success("Notificação deletada!");
    }
    toast.dismiss(toastId);
    return toast.error("Erro ao deletar notificação!");
  }
  async function notifyReprocess(id: string) {
    const toastId = toast.loading("Reprocessando notificação...");
    const _post = await reprocessNotify(id);
    if (_post?.code && _post?.code == 200) {
      toast.dismiss(toastId);
      setReload(true);
      return toast.success("Notificação Reprocessada!");
    }
    toast.dismiss(toastId);
    return toast.error("Erro ao Reprocessar notificação!");
  }
  function replaceWithBr(text?: any) {
    return text?.replace(/\n/g, "<br />");
  }
  return (
    <div
      className="notifications"
      style={{ backgroundColor: theme()?.background_color }}
    >
      {popup == "create" && (
        <CreateNotify setReload={setReload} setPopup={setPopup} />
      )}
      <Sidebar
        id="notifications"
        light={() => {
          setUsetheme("light_theme");
        }}
        dark={() => {
          setUsetheme("dark_theme");
        }}
      />
      <div className="content_dashboard">
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div className="p1_title">
          <div className="texts">
            <h1 className="title" style={{ color: theme()?.font.bold }}>
              Notificações
            </h1>
            <p style={{ color: theme()?.font.regular }}>
              Envie avisos para os usuários do sistema!
            </p>
          </div>
          <div className="resumes">
            {loading && (
              <>
                <Shimmer width="150px" height="70px" radius="15px" />
              </>
            )}
            {!loading && (
              <>
                {initialMenu?.map((res: any, i: number) => {
                  return (
                    <div className="item_menu" key={i}>
                      <div
                        style={{ backgroundColor: theme()?.container_main }}
                        className="icon_container"
                      >
                        {res?.icon}
                      </div>
                      <div className="texts">
                        <p style={{ color: theme()?.font.regular }}>
                          {res?.title}
                        </p>
                        <h1 style={{ color: theme()?.font.bold }}>
                          {res?.value}
                        </h1>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div
          style={{ backgroundColor: theme()?.container_main }}
          className="warning_bar"
        >
          <div className="p1_warnign">
            <div
              style={{ backgroundColor: theme()?.container_second }}
              className="icon_container"
            >
              <AiOutlineNotification
                size={"1.2EM"}
                color={theme()?.icon.default}
              />
            </div>
            <div className="texts">
              <h3 style={{ color: theme()?.font.bold }}>
                Todas as Notificações
              </h3>
              <p style={{ color: theme()?.font.regular }}>
                Hoje - {new Date().toDateString()}
              </p>
            </div>
          </div>
          <div className="buttons">
            <div
              style={{ backgroundColor: theme()?.main_color, height: "60px" }}
              className="download_button"
              onClick={() => {
                setPopup("create");
              }}
            >
              Criar notificação
              <MdAdd color={theme()?.icon?.default} />
            </div>
          </div>
        </div>
        <div className="list_notify">
          {filterNotify?.length == 0 && (
            <div className="none">
              <MdSearchOff size={"1.8em"} color={theme()?.font.regular} />
              <p style={{ color: theme()?.font.regular }}>
                Nenhuma notifcação encontrada!
              </p>
            </div>
          )}
          {filterNotify?.length != 0 && (
            <>
              {filterNotify?.map((res: any, i: number) => {
                return (
                  <div
                    key={i}
                    className="notify_item"
                    style={{ background: theme()?.container_main }}
                  >
                    <div className="p1">
                      <div
                        style={{ background: theme()?.container_second }}
                        className="icon_container"
                      >
                        <MdOutlineNotifications
                          size={"1.3em"}
                          color={theme()?.icon.default}
                        />
                      </div>
                      <div
                        style={{ background: theme()?.container_second }}
                        className="container_item"
                      >
                        <p style={{ color: theme()?.font.regular }}>Tipo</p>
                        <h4 style={{ color: theme()?.font.bold }}>
                          {res?.status == "update" ? "Atualização" : "Aviso"}
                        </h4>
                      </div>
                      <div
                        style={{ background: theme()?.container_second }}
                        className="container_item"
                      >
                        <p style={{ color: theme()?.font.regular }}>Nome</p>
                        <h4 style={{ color: theme()?.font.bold }}>
                          {res?.title}
                        </h4>
                      </div>
                      <div
                        style={{ background: theme()?.container_second }}
                        className="container_item"
                      >
                        <p style={{ color: theme()?.font.regular }}>
                          Data de criação
                        </p>
                        <h4 style={{ color: theme()?.font.bold }}>
                          {new Date(res?.created_date).toLocaleDateString()}
                        </h4>
                      </div>
                      <div
                        style={{ background: theme()?.container_second }}
                        className="container_item"
                        onMouseEnter={() => {
                          setPopup("detail");
                          setText(res?.text);
                        }}
                        onMouseLeave={() => {
                          setPopup("");
                          setText("");
                        }}
                      >
                        <p style={{ color: theme()?.font.regular }}>Conteúdo</p>
                        <h4 style={{ color: theme()?.font.bold }}>
                          {res?.text.substring(0, 15)}...
                        </h4>
                        {popup == "detail" && text == res?.text ? (
                          <div
                            style={{
                              background: theme()?.container_main,
                              color: theme()?.font.regular,
                            }}
                            className="container_text"
                            dangerouslySetInnerHTML={{
                              __html: replaceWithBr(text),
                            }}
                          ></div>
                        ) : null}
                      </div>
                    </div>
                    <div className="p2">
                      <div
                        style={{ background: theme()?.container_second }}
                        className="tool_icon"
                        onClick={() => {
                          notifyReprocess(res?._id);
                        }}
                      >
                        <MdLoop size={"1.2em"} color={theme()?.icon.default} />
                      </div>
                      <div
                        onClick={() => {
                          notificationDelete(res?._id);
                        }}
                        className="delete_icon"
                      >
                        <MdDelete size={"1.2em"} color="red" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
