//Default
import axios from "axios";

import { environment } from "../data/environment";

export async function editPost({id , title , main_image , content , author , related , related_links} : {id?:string , title?: string , main_image? :string , content?: string , author?: string , related?: string , related_links?: string}) {
    const BLtoken = localStorage.getItem("@BL:Token")
    const _put = await axios.put(`${environment.DB_CONNECT}/blog/${id}` , {
        title: title,
        main_image: main_image,
        content: content,
        author:author,
        related: related,
        related_links: related_links
    } , {
        headers: {token: BLtoken}
    });
    if(_put.data.code && _put.data.code == 200) {
        return _put.data.return
    }
    return "Erro ao editar"
}
export async function editCoupon({id , name , discount , fantasy_name} : {id?:string , name?: string , discount? :number , fantasy_name?: string }) {
    const token = localStorage.getItem("@WR:Token")
    const _put = await axios.put(`${environment.DB_RANKING}/coupon/edit/${id}` , {
        name: name,
        discount: discount,
        fantasy_name: fantasy_name
    } , {
        headers: {"x-access-token": token}
    });
    if(_put.data.code && _put.data.code == 200) {
        return _put.data
    }
    return {code:500 , message:"Erro ao editar"}
}
export async function editPlans({id , value} : {id?:string , value: string}) {
    const token = localStorage.getItem("@WR:Token")
    const _put = await axios.put(`${environment.DB_RANKING}/company/plan/${id}` , {
        value: value
    } , {
        headers: {"x-access-token": token}
    });
    if(_put.data.code && _put.data.code == 200) {
        return _put.data
    }
    return {code:500 , message:"Erro ao editar"}
}