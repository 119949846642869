export function formatPhoneNumber(number: number | string): string {
    const regex = /^(\d{2})(\d{5})(\d{4})$/;
    return number.toString().replace(regex, "($1) $2-$3");
  }
  
  export function formatNewphone(value: number | string, typeN: number, typeMax: number): string | undefined {
    if (!value) return value as string;
    const phoneNumber = value.toString().replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
  
    if (phoneNumberLength < 2) return phoneNumber;
    if (phoneNumberLength > 2 && phoneNumberLength < typeN) {
      return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2)}`;
    }
    if (phoneNumberLength > typeN) {
      return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(
        2,
        typeN
      )}-${phoneNumber.substring(typeN, typeMax)}`;
    }
  }