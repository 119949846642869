import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect, useState } from "react";

//Components
import { theme } from "../../theme";
import Button from "../button/buttons";

interface popUpProps {
    title?: string,
    subTitle?: string,
    closeButton?: any,
    textOne?: string,
    textTwo?: string,
    OneButton?: any,
    TwoButton?: any,
    loading?: string
}

export default function Popup({ title, subTitle, closeButton, textOne, textTwo, OneButton, TwoButton, loading }: popUpProps) {
    const [state, setState] = useState<string>("CONFIRM")
    useEffect(() => {
        if (loading == "pending") {
            setState("LOADING")
        }
    }, [loading])
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            position: "absolute",
            left: "0",
            top: "0",
            zIndex: "99999999"
        }}>
            {state == "CONFIRM" && (
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "350px",
                    maxWidth: "100%",
                    height: "auto",
                    padding: "25px",
                    boxSizing: "border-box",
                    backgroundColor: theme()?.container_main,
                    borderRadius: "15px",
                }}>
                    <h2 style={{ fontSize: "24px", textAlign: "center" , color: theme()?.font.bold }}>{title}</h2>
                    <p style={{ fontSize: "14px", textAlign: "center", marginTop: "10px" ,  color: theme()?.font.regular }}>{subTitle}</p>
                    <div style={{ display: "flex", width: "100%", alignItems: "center", marginTop: "20px", justifyContent: "space-between" }}>
                        <Button
                            onTap={() => {
                                OneButton()
                            }}
                            text={textOne}
                            width="49%"
                            backcolor={theme()?.container_second}
                        />
                        <Button
                            onTap={() => {
                                TwoButton()
                            }}
                            text={textTwo}
                            width="49%"
                            backcolor={theme()?.main_color}
                            
                        />
                    </div>
                </div>
            )}
            {state == "LOADING" && (
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "350px",
                    maxWidth: "100%",
                    height: "auto",
                    padding: "25px",
                    boxSizing: "border-box",
                    backgroundColor: "white",
                    borderRadius: "15px",
                }}>
                    <Player
                        src="https://assets8.lottiefiles.com/packages/lf20_b88nh30c.json"
                        style={{ width: "250px", height: "auto" }}
                        autoplay={true}
                        loop={true}
                    />
                </div>
            )}
        </div>
    )
}