import { useState } from "react";
import {
  AiOutlineBuild,
  AiOutlineDashboard,
  AiOutlineMenu,
  AiOutlineNotification,
  AiOutlineUser,
} from "react-icons/ai";

//IMG
import defaultUser from "../../img/default_img.jpg";
import Logo from "../../img/trofeu.png";

//Style
import { MdClose, MdLogout, MdModeNight, MdSunny } from "react-icons/md";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { theme } from "../../theme";
import "./sidebar.css";

export function Menu({
  item,
  setPopup,
  id,
}: {
  item?: any;
  setPopup?: any;
  id?: string;
}) {
  return (
    <div className="background_menu">
      <div
        style={{ backgroundColor: theme()?.container_main }}
        className="menu_content"
      >
        <div className="close">
          <MdClose
            onClick={() => {
              setPopup("");
            }}
            size={"2em"}
            color={theme()?.icon?.default}
          />
        </div>
        <div
          style={{ backgroundColor: theme()?.container_second }}
          className="user_profile"
        >
          <img src={defaultUser} />
          <h2 style={{ color: theme()?.font.bold }}>admin</h2>
        </div>
        <div
          className="line"
          style={{ backgroundColor: theme()?.line_container }}
        ></div>
        <div className="menu_list">
          {item?.map((res: any, i: number) => {
            return (
              <Link to={res?.path} key={i} className="item_menu">
                {res?.id == id && (
                  <div
                    style={{ backgroundColor: theme()?.main_color }}
                    className="dot_menu"
                  ></div>
                )}
                <p style={{ color: theme()?.font.bold }}>{res?.name}</p>
              </Link>
            );
          })}
        </div>
        <div
          className="line"
          style={{ backgroundColor: theme()?.line_container }}
        ></div>
        <div
          onClick={() => {
            localStorage.removeItem("@WR:Token");
            localStorage.removeItem("@BL:Token");
            window.location.href = "/login";
          }}
          className="item_menu"
        >
          <p style={{ color: "#ff0000" }}>Sair do Painel</p>
          <MdLogout size={"1.4em"} color="#ff0000" />
        </div>
      </div>
    </div>
  );
}
export default function Sidebar({
  id,
  light,
  dark,
}: {
  id?: string;
  light?: any;
  dark?: any;
}) {
  const [itens, setItens] = useState<[] | any>([
    {
      id: "overview",
      name: "Overview",
      icon: <AiOutlineDashboard size="1.3em" />,
      path: "/dashboard",
    },
    {
      id: "companys",
      name: "Companhias",
      icon: <AiOutlineBuild size="1.3em" />,
      path: "/companys",
    },
    {
      id: "users",
      name: "Usuários",
      icon: <AiOutlineUser size="1.3em" />,
      path: "/users",
    },
    {
      id: "notifications",
      name: "Notificações",
      icon: <AiOutlineNotification size="1.3em" />,
      path: "/notifications",
    },
  ]);
  const [popUp, setPopup] = useState("");
  const [usetheme, setUsetheme] = useLocalStorage(
    "theme",
    window.localStorage.getItem("theme")
  );

  return (
    <div className="sidebar">
      {popUp == "MENU" && <Menu item={itens} setPopup={setPopup} id={id} />}
      <div className="content">
        <img src={Logo} />
        <div
          style={{ backgroundColor: theme()?.container_main }}
          className="tool_bar"
        >
          {itens.map((res: any, i: number) => {
            return (
              <Link
                key={i}
                to={res?.path}
                className={`item ${res.id == id && "select"}`}
                style={{
                  borderColor:
                    res.id == id ? theme()?.line_container : "transparent",
                }}
              >
                {res.id == id && (
                  <div
                    className="dot"
                    style={{ backgroundColor: theme()?.main_color }}
                  ></div>
                )}
                <p style={{ color: theme()?.font.bold }}>{res.name}</p>
              </Link>
            );
          })}
        </div>
        <div className="p3_profile">
          <div
            className="icon_container"
            onClick={() => {
              dark();
            }}
            style={{
              backgroundColor:
                localStorage.getItem("theme") == "dark_theme"
                  ? theme()?.main_color
                  : theme()?.container_main,
            }}
          >
            <MdModeNight size={"1.3em"} color={theme()?.icon.default} />
          </div>
          <div
            className="icon_container"
            onClick={() => {
              light();
            }}
            style={{
              backgroundColor:
                localStorage.getItem("theme") == "light_theme"
                  ? theme()?.main_color
                  : theme()?.container_main,
            }}
          >
            <MdSunny size={"1.3em"} color={theme()?.icon.default} />
          </div>
          <AiOutlineMenu
            onClick={() => {
              setPopup("MENU");
            }}
            className="menu"
            size={"2em"}
            color={theme()?.icon.default}
          />
          <div
            onClick={() => {
              localStorage.removeItem("@WR:Token");
              localStorage.removeItem("@BL:Token");
              window.location.href = "/login";
            }}
            className="user_container"
            style={{ backgroundColor: theme()?.container_main }}
          >
            <img src={defaultUser} />
            <div className="text">
              <h3 style={{ color: theme()?.font.bold }}>Admin</h3>
            </div>
            <MdLogout size={"1.3em"} color="#ff0000" />
          </div>
        </div>
      </div>
    </div>
  );
}
