interface buttonProps {
    text?: string,
    disable?: boolean,
    backcolor?: string,
    width?: string,
    height?: string,
    margin?: string,
    onTap?: any
}

export default function Button({ text, disable, backcolor, width, height, margin, onTap }: buttonProps) {
    return (
        <button style={{
            width: width ? width : "200px",
            height: height ? height : "35px",
            backgroundColor: backcolor ? backcolor : "var(--main-color)",
            border: "none",
            borderRadius: "35px",
            color: "white",
            cursor: "pointer",
            fontSize: "16px",
            margin: margin
        }}
            disabled={disable ? disable : false}
            onClick={() => {
                onTap()
            }}>
            {text}
        </button>
    )
}