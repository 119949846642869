import axios from "axios";
import { toast } from "react-hot-toast";

//data
import { environment } from "../data/environment";

export async function deleteID({id} : {id: string}) {
    const BLtoken = localStorage.getItem("@BL:Token")
    const _delete = await axios.delete(`${environment.DB_CONNECT}/delete/${id}` , {
        headers: {
            token: BLtoken
        }
    });
    if(_delete.data.code && _delete.data.code == 200) {
        toast.success("Artigo deletado com sucesso!")
        return _delete.data
    }
    return toast.error("Erro ao deletar")

}
export async function deleteNotification(id?: string) {
    const token = localStorage.getItem("@WR:Token")
    const _delete = await axios.delete(`${environment.DB_RANKING}/notification/${id}` , {
        headers: {
            "x-access-token": token
        }
    });
    console.log(_delete)
    if(_delete.data.code && _delete.data.code == 200) {
        return _delete.data
    }
    return {code: 500 , message: "Erro ao deletar"}

}

export async function deleteCoupon({id} : {id?:string}) {
    const token = localStorage.getItem("@WR:Token")
    const _delete = await axios.delete(`${environment.DB_RANKING}/coupon/delete/${id}` , {
        headers: {"x-access-token": token}
    });
    if(_delete.data.code && _delete.data.code == 200) {
        return _delete.data
    }
    return {code:500 , message:"Erro ao deletar"}
}

export async function deleteCompany({id} : {id: string | null}) {
    const token = localStorage.getItem("@WR:Token");
    const _delete = await axios.delete(`${environment.DB_RANKING}/company/${id}` , {
        headers: {"x-access-token": token}
    });
    if(_delete.data.code && _delete.data.code == 200) {
        return _delete.data
    }
    return {code:500 , message: "Erro ao deletar!"}
}
export async function deleteUser({id , userid} : {id: string | null , userid: string | null}) {
    const token = localStorage.getItem("@WR:Token");
    const _delete = await axios.delete(`${environment.DB_RANKING}/user/${userid}/company/${id}` , {
        headers: {"x-access-token": token}
    });
    if(_delete.data.code && _delete.data.code == 200) {
        return _delete.data
    }
    return {code:500 , message: "Erro ao deletar!"}
}