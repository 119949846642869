//Default imports
import { useState } from "react";
import { toast } from "react-hot-toast";

//Services
import { authenticate } from "../../services/post";

//Components
import Input from "../../components/input/input";

//Style
import { theme } from "../../theme";
import "./login.css";


export default function Login() {
    const [data, setData] = useState({
        email: "",
        password: ""
    });
    async function auth() {
        const _action = await authenticate({ email: data.email, password: data.password });
        if (_action.admin_type && _action.admin_type != 4) {
            toast.error("Você não tem permissão para acessar!")
        }
        if (_action.admin_type == 4 && _action.token) {
            window.location.href = "/dashboard"
            localStorage.setItem("@WR:Token", _action.token);
            localStorage.setItem("@BL:Token", "-i5*T&SPew2St@36u&lth1f7$9tU3=J238uBi!erAFRI-#O#Ln?70IW9_&&iRiY5")
            return toast.success("Login efetuado com sucesso!");
        }
        if (_action.code == 403) {
            toast.error("Senha incorreta");
        }
        if (_action.code == 404) {
            toast.error("Usuário não encontrado!");
        }
    }
    return (
        <div className="login" style={{
            backgroundColor: theme()?.background_color
        }}>
            <div style={{
            backgroundColor: theme()?.container_main
        }} className="login-content">
                <div className="left">
                    <h1 style={{color:theme()?.font.bold}}>Logar no Painel</h1>
                    <p style={{color:theme()?.font.regular}}>Gerencie o sistema de forma fácil</p>
                    <div className="line" style={{backgroundColor: theme()?.line_container}}></div>
                    <Input
                        text="E-mail"
                        placeholder="Digite seu e-mail"
                        onValue={(value: any) => {
                            setData({ ...data, email: value })
                        }}
                        margin="0px 0px 20px 0px"
                    />
                    <Input
                        text="Senha"
                        placeholder="************"
                        type={"password"}
                        onValue={(value: any) => {
                            setData({ ...data, password: value })
                        }}
                        margin="0px 0px 0px 0px"
                        onKey={(value:any) =>{
                            if(value == "Enter") {
                                auth()
                            }
                        }}
                    />
                    <button style={{
                        backgroundColor: theme()?.main_color
                    }} onClick={() => { auth() }}>Fazer Login</button>
                </div>
                <div className="right" style={{backgroundColor: theme()?.container_second}}>

                </div>
            </div>
        </div>
    )
}