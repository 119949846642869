//Default
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Services
import { getUsers } from "../../services/get";

//Functions

//Components

//Icons
import { AiOutlineUser } from "react-icons/ai";

//Images
import defaultUser from "../../img/default_img.jpg";

//Style
import { MdSearch } from "react-icons/md";
import Shimmer from "../../components/shimmer/shimmer";
import Sidebar from "../../components/sidebar/sidebar";
import { getUrl } from "../../functions/getUrl";
import useLocalStorage from "../../hooks/useLocalStorage";
import { theme } from "../../theme";
import "./users.css";

export default function Users() {
  const [initialMenu, setInitialMenu] = useState<[] | any>();
  const [users, setUsers] = useState<[] | any>([]);
  const [filterUsers, setFilterUsers] = useState<[] | any>([]);
  const [paymentCompanys, setPaymentCompanys] = useState<Number>(0);
  const [dates, setDates] = useState<[] | any>([]);
  const [pack, setPack] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [usetheme, setUsetheme] = useLocalStorage(
    "theme",
    window.localStorage.getItem("theme")
  );
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const [usersarr] = await Promise.all([getUsers()]);
      setUsers(usersarr?.data);
      setFilterUsers(usersarr?.data);
      setLoading(false);
    }
    fetchData();
  }, []);
  useEffect(() => {
    setFilterUsers(
      users?.filter((res: any) =>
        res.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);
  useEffect(() => {
    setInitialMenu([
      {
        title: "Usuários cadastrados",
        icon: <AiOutlineUser size={"2em"} color={theme()?.icon.default} />,
        value: users?.length,
      },
    ]);
  }, [users, paymentCompanys, usetheme]);

  function compare(a: any, b: any) {
    if (!a?.created_date || !b?.created_date) return 0;

    // Conversão das strings de data em objetos Date para ordenação
    const dateA: Date = new Date(a.created_date);
    const dateB: Date = new Date(b.created_date);

    // Ordenação das datas
    return dateB.getTime() - dateA.getTime();
  }
  return (
    <div
      className="dashboard_users"
      style={{ backgroundColor: theme()?.background_color }}
    >
      <Sidebar
        id="users"
        light={() => {
          setUsetheme("light_theme");
        }}
        dark={() => {
          setUsetheme("dark_theme");
        }}
      />
      <div className="content_dashboard">
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div className="p1_title">
          <div className="texts">
            <h1 className="title" style={{ color: theme()?.font.bold }}>
              Usuários
            </h1>
            <p style={{ color: theme()?.font.regular }}>
              Usuários que estão cadastrados no sistema.
            </p>
          </div>
          <div className="resumes">
            {loading && (
              <>
                <Shimmer width="250px" height="100px" radius="15px" />
              </>
            )}
            {!loading && (
              <>
                {initialMenu?.map((res: any, i: number) => {
                  return (
                    <div className="item_menu" key={i}>
                      <div
                        style={{ backgroundColor: theme()?.container_main }}
                        className="icon_container"
                      >
                        {res?.icon}
                      </div>
                      <div className="texts">
                        <p style={{ color: theme()?.font.regular }}>
                          {res?.title}
                        </p>
                        <h1 style={{ color: theme()?.font.bold }}>
                          {res?.value}
                        </h1>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div
          style={{ backgroundColor: theme()?.container_main }}
          className="warning_bar"
        >
          <div className="p1_warnign">
            <div
              style={{ backgroundColor: theme()?.container_second }}
              className="icon_container"
            >
              <AiOutlineUser size={"1.2EM"} color={theme()?.icon.default} />
            </div>
            <div className="texts">
              <h3 style={{ color: theme()?.font.bold }}>Todos os usuários</h3>
              <p style={{ color: theme()?.font.regular }}>
                Hoje - {new Date().toDateString()}
              </p>
            </div>
          </div>
        </div>
        <div className="filter_bar" style={{ borderColor: theme()?.line }}>
          <p style={{ color: theme()?.font.bold }}>Filtros: </p>
          <div
            style={{
              borderColor: theme()?.line,
              backgroundColor: "transparent",
            }}
            className="input_search"
          >
            <input
              style={{
                color: theme()?.font.bold,
              }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Pesquisa por nome"
              value={search}
            />
            <MdSearch color={theme()?.icon.default} size={"1.1em"} />
          </div>
        </div>
        <div className="p1_content">
          {loading && (
            <>
              <Shimmer width="100%" height="100%" radius="25px" />
            </>
          )}
          {!loading && (
            <>
              <div className="container_over company">
                <div
                  style={{ borderColor: theme()?.line }}
                  className="main_title_table"
                >
                  <div className="row">
                    <AiOutlineUser
                      size={"1.2em"}
                      color={theme()?.icon.default}
                    />
                    <h4 style={{ color: theme()?.font.bold }}>
                      Usuários({users?.length})
                    </h4>
                  </div>
                </div>
                <table>
                  <tr
                    style={{
                      backgroundColor: theme()?.container_main,
                      color: theme()?.font.bold,
                    }}
                    className="title_table"
                  >
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Data de criação</th>
                    <th>N°Times</th>
                    <th>CPF</th>
                    <th>Último Login</th>
                    <th>ID/Companhia</th>
                  </tr>
                  {filterUsers?.sort(compare).map((res: any, i: number) => {
                    return (
                      <tr
                        key={i}
                        className="other_tr"
                        style={{ borderColor: theme()?.line }}
                      >
                        <td
                          style={{
                            borderColor: theme()?.line,
                            color: theme()?.font.regular,
                          }}
                        >
                          {res._id}
                        </td>
                        <td
                          style={{
                            borderColor: theme()?.line,
                            color: theme()?.font.regular,
                          }}
                        >
                          <div className="container_img">
                            <img
                              src={getUrl(res?.avatar)}
                              onError={(e) => {
                                e.currentTarget.src = defaultUser;
                              }}
                            />
                            {res.name}
                          </div>
                        </td>
                        <td
                          style={{
                            borderColor: theme()?.line,
                            color: theme()?.font.regular,
                          }}
                        >
                          {new Date(res.created_date).toLocaleDateString()}
                        </td>
                        <td
                          style={{
                            borderColor: theme()?.line,
                            color: theme()?.font.regular,
                          }}
                        >
                          {res.teams.length}
                        </td>
                        <td
                          style={{
                            borderColor: theme()?.line,
                            color: theme()?.font.regular,
                          }}
                        >
                          {res.cpf}
                        </td>
                        <td
                          style={{
                            borderColor: theme()?.line,
                            color: theme()?.font.regular,
                          }}
                        >
                          {res?.last_login ? (
                            <>
                              {new Date(res.last_login).toLocaleDateString()} ás{" "}
                              {new Date(res.last_login).toLocaleTimeString()}
                            </>
                          ) : (
                            "Esperando login..."
                          )}
                        </td>
                        <td
                          style={{
                            borderColor: theme()?.line,
                            color: theme()?.font.regular,
                          }}
                        >
                          {res.company_id}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
