//data
import axios from "axios";
import { environment } from "../data/environment";


export async function authenticate({ email, password }: {
    email?: string,
    password?: string
}) {
    const _authenticate = await axios.post(`${environment.DB_RANKING}/user/authenticate` , {
        email: email,
        password:password
    });
    if(_authenticate.data.code && _authenticate.data.code == 500) {
        return _authenticate.data
    }
    if(_authenticate.data.code && _authenticate.data.code == 404) {
        return _authenticate.data
    }
    if(_authenticate.data.code && _authenticate.data.code == 403) {
        return _authenticate.data
    }
        return _authenticate.data
}

export async function uploadImage({formdata}: {formdata?: any}) {
    const BLtoken = localStorage.getItem("@BL:Token")
    const _post = await axios.post(`${environment.DB_CONNECT}/image` , formdata , {
        headers: {
            token: BLtoken
        }
    });
    if(_post.data.code && _post.data.code == 200) {
        return _post.data
    }
    return "Falha ao salvar!"
}

export async function createPost({title , main_image , content , author , related , related_links} : {id?:string , title?: string , main_image? :string , content?: string , author?: string , related?: string , related_links?: string}) {
    const BLtoken = localStorage.getItem("@BL:Token")
    const _post = await axios.post(`${environment.DB_CONNECT}/blog/create` , {
        title: title,
        main_image: main_image,
        content: content,
        author:author,
        related: related,
        related_links: related_links
    } , {
        headers: {token: BLtoken}
    });
    if(_post.data.code && _post.data.code == 200) {
        return _post.data.return
    }
    return "Erro ao editar"
}

export async function createCoupon({name , discount , fantasy_name} : {id?:string , name?: string , discount? :number , fantasy_name?: string }) {
    const token = localStorage.getItem("@WR:Token")
    const _put = await axios.post(`${environment.DB_RANKING}/create/coupon` , {
        name: name,
        discount: discount,
        fantasy_name: fantasy_name
    } , {
        headers: {"x-access-token": token}
    });
    if(_put.data.code && _put.data.code == 200) {
        return _put.data
    }
    return {code:500 , message:"Erro ao editar"}
}

export async function editNumberTeam({id , value} : {id?:string , value?:number}) {
    const token = localStorage.getItem("@WR:Token")
    const _put = await axios.put(`${environment.DB_RANKING}/company/team/${id}` , {
        value: value
    } , {
        headers: {"x-access-token": token}
    });
    if(_put.data.code && _put.data.code == 200) {
        return _put.data
    }
    return {code:500 , message:"Erro ao editar"}
}
export async function createNotify({status , title,text,links} : {status?:string,title?:string,text?:string , links?:string[] }) {
    const token = localStorage.getItem("@WR:Token")
    const _post = await axios.post(`${environment.DB_RANKING}/v1/notification/create` , {
        status,
        title,
        text,
        links,
    } , {
        headers: {"x-access-token": token}
    });
    if(_post.data.code && _post.data.code == 200) {
        return _post.data
    }
    return {code:500 , message:"Erro ao criar!"}
}
export async function reprocessNotify(id?:string) {
    const token = localStorage.getItem("@WR:Token")
    const _post = await axios.post(`${environment.DB_RANKING}/v1/notification/reprocess/${id}` , {} , {
        headers: {"x-access-token": token}
    });
    if(_post.data.code && _post.data.code == 200) {
        return _post.data
    }
    return {code:500 , message:"Erro ao criar!"}
}