//Default
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Services
import { getCompanys, getUsers } from "../../services/get";

//Functions
import { getLastDays } from "../../functions/getDate";
import { getUrl } from "../../functions/getUrl";
import { month_data } from "./data/data_month";

//Components

//Icons
import { AiOutlineUser } from "react-icons/ai";
import { BsBuildings } from "react-icons/bs";

//Images
import companyPlaceholder from "../../img/company_placeholder.png";
import userDefault from "../../img/default_img.jpg";

//Style
import { MdCalendarMonth, MdMoneyOff } from "react-icons/md";
import Shimmer from "../../components/shimmer/shimmer";
import Sidebar from "../../components/sidebar/sidebar";
import useLocalStorage from "../../hooks/useLocalStorage";
import { theme } from "../../theme";
import "./dashboard.css";

export default function Dashboard() {
  const [initialMenu, setInitialMenu] = useState<[] | any>();
  const [companys, setCompanys] = useState<[] | any>([]);
  const [filterCompany, setFilterCompany] = useState<[] | any>([]);
  const [users, setUsers] = useState<[] | any>([]);
  const [paymentCompanys, setPaymentCompanys] = useState<Number>(0);
  const [dates, setDates] = useState<[] | any>([]);
  const [pack, setPack] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [usetheme, setUsetheme] = useLocalStorage(
    "theme",
    window.localStorage.getItem("theme")
  );
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const [companysarr, usersarr] = await Promise.all([
        getCompanys(),
        getUsers(),
      ]);
      setCompanys(companysarr.data);
      setFilterCompany(companysarr.data);
      setUsers(usersarr.data);
      setDates(
        companysarr?.data
          ?.sort(compare)
          .map((res: any) => new Date(res.created_date).toDateString())
      );
      setLoading(false);
    }
    fetchData();
  }, []);
  useEffect(() => {
    setInitialMenu([
      {
        title: "Companhias cadastradas",
        icon: <BsBuildings size={"2em"} color={theme()?.icon.default} />,
        value: companys?.length,
      },
      {
        title: "Usuários cadastrados",
        icon: <AiOutlineUser size={"2em"} color={theme()?.icon.default} />,
        value: users?.length,
      },
      {
        title: "Companhias pagantes",
        icon: <MdMoneyOff size={"2em"} color={theme()?.icon.default} />,
        value: companys?.filter(
          (res: any) => res.account_payment.account_type != "not-allowed" && res.account_payment.account_type != "permanent"
        )?.length,
      },
    ]);
  }, [users, companys, paymentCompanys, usetheme]);

  useEffect(() => {
    if (pack != null) {
      setDates(
        companys
          ?.sort(compare)
          .filter((res: any) => res?.account_payment?.account_type == pack)
          .map((res: any) => new Date(res.created_date).toDateString())
      );
      setFilterCompany(
        companys?.filter(
          (res: any) => res?.account_payment?.account_type == pack
        )
      );
    }
    if (pack == null) {
      setDates(
        companys
          ?.sort(compare)
          .map((res: any) => new Date(res.created_date).toDateString())
      );
      setFilterCompany(companys);
    }
  }, [pack]);

  function compare(a: any, b: any) {
    if (!a?.created_date || !b?.created_date) return 0;

    // Conversão das strings de data em objetos Date para ordenação
    const dateA: Date = new Date(a.created_date);
    const dateB: Date = new Date(b.created_date);

    // Ordenação das datas
    return dateB.getTime() - dateA.getTime();
  }
  return (
    <div
      className="dashboard"
      style={{ backgroundColor: theme()?.background_color }}
    >
      <Sidebar
        id="overview"
        light={() => {
          setUsetheme("light_theme");
        }}
        dark={() => {
          setUsetheme("dark_theme");
        }}
      />
      <div className="content_dashboard">
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div className="p1_title">
          <div className="texts">
            <h1 className="title" style={{ color: theme()?.font.bold }}>
              Visão Geral
            </h1>
            <p style={{ color: theme()?.font.regular }}>
              Confira um panorama geral de como está o sistema
            </p>
          </div>
          <div className="resumes">
            {loading && (
              <>
                <Shimmer width="250px" height="100px" radius="15px" />
                <Shimmer width="250px" height="100px" radius="15px" />
                <Shimmer width="250px" height="100px" radius="15px" />
              </>
            )}
            {!loading && (
              <>
                {initialMenu?.map((res: any, i: number) => {
                  return (
                    <div key={i} className="item_menu">
                      <div
                        style={{ backgroundColor: theme()?.container_main }}
                        className="icon_container"
                      >
                        {res?.icon}
                      </div>
                      <div className="texts">
                        <p style={{ color: theme()?.font.regular }}>
                          {res?.title}
                        </p>
                        <h1 style={{ color: theme()?.font.bold }}>
                          {res?.value}
                        </h1>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div
          style={{ backgroundColor: theme()?.container_main }}
          className="warning_bar"
        >
          <div className="p1_warnign">
            <div
              style={{ backgroundColor: theme()?.container_second }}
              className="icon_container"
            >
              <MdCalendarMonth size={"1.2EM"} color={theme()?.icon.default} />
            </div>
            <div className="texts">
              <h3 style={{ color: theme()?.font.bold }}>
                Visão dos últimos dias
              </h3>
              <p style={{ color: theme()?.font.regular }}>
                Hoje - {new Date().toDateString()}
              </p>
            </div>
          </div>
          <div className="buttons">
            <div
              onClick={() => {
                navigate("/companys");
              }}
              style={{
                borderColor: theme()?.container_second,
                color: theme()?.font.bold,
              }}
              className="download_button all"
            >
              Ver todas
            </div>
          </div>
        </div>
        <div className="filter_bar" style={{ borderColor: theme()?.line }}>
          <p style={{ color: theme()?.font.bold }}>Filtros: </p>
          <div className="filter_container">
            <p
              style={{
                color: theme()?.font.regular,
              }}
            >
              Tipo de plano:{" "}
            </p>
            <select
              onChange={(e) => {
                if (e.target.value == "Todos") {
                  return setPack(null);
                }
                if (e.target.value == "Sem plano")
                  return setPack("not-allowed");
                if (e.target.value == "Start") return setPack("simple");
                if (e.target.value == "Pro") return setPack("pro");
                if (e.target.value == "Premium") return setPack("premium");
              }}
              style={{
                color: theme()?.font.regular,
                borderColor: theme()?.line,
              }}
              defaultValue={"Todos"}
            >
              <option style={{ backgroundColor: theme()?.container_main }}>
                Todos
              </option>
              <option style={{ backgroundColor: theme()?.container_main }}>
                Sem plano
              </option>
              <option style={{ backgroundColor: theme()?.container_main }}>
                Start
              </option>
              <option style={{ backgroundColor: theme()?.container_main }}>
                Pro
              </option>
              <option style={{ backgroundColor: theme()?.container_main }}>
                Premium
              </option>
            </select>
          </div>
        </div>
        <div className="p1_content">
          {loading && (
            <>
              <Shimmer width="100%" height="100%" radius="25px" />
              <Shimmer width="50%" height="100%" radius="25px" />
              <Shimmer width="100%" height="100%" radius="25px" />
            </>
          )}
          {!loading && (
            <>
              <div
                className="container_over flow"
                style={{ backgroundColor: theme()?.container_main }}
              >
                <div
                  style={{ borderColor: theme()?.line_container }}
                  className="main_title_table"
                >
                  <div className="row">
                    <MdCalendarMonth
                      size={"1.2em"}
                      color={theme()?.icon.default}
                    />
                    <h4 style={{ color: theme()?.font.bold }}>
                      Calendário de criação
                    </h4>
                  </div>
                </div>
                <div className="list_flow">
                  {getLastDays(66).map((res: any, i: number) => {
                    const date = new Date(res).toDateString();
                    const month = new Date(res).getMonth();
                    const day = new Date(res).getDate();
                    const unit = dates?.filter(
                      (res: any) => res == date
                    )?.length;
                    return (
                      <div
                        key={i}
                        title={`${unit} Companhias`}
                        style={{ backgroundColor: theme()?.container_second }}
                        className="date_button"
                      >
                        <p style={{ color: theme()?.font.regular }}>
                          {month_data[month]}/{day}
                        </p>
                        <div
                          className="dots"
                          style={{
                            backgroundColor: dates?.includes(date)
                              ? theme()?.main_color
                              : theme()?.container_main,
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="container_over user"
                style={{ backgroundColor: theme()?.container_main }}
              >
                <div
                  style={{ borderColor: theme()?.line_container }}
                  className="main_title_table"
                >
                  <div className="row">
                    <AiOutlineUser
                      size={"1.2em"}
                      color={theme()?.icon.default}
                    />
                    <h4 style={{ color: theme()?.font.bold }}>
                      Últimos usuários
                    </h4>
                  </div>
                  <p
                    onClick={() => {
                      navigate("/users");
                    }}
                    style={{ color: theme()?.main_color }}
                  >
                    Ver todos
                  </p>
                </div>
                <div className="list_user">
                  {users
                    ?.slice(0, 8)
                    .sort(compare)
                    .map((res: any, i: number) => {
                      return (
                        <div key={i} className="item_user">
                          <img
                            onError={(e) => {
                              e.currentTarget.src = userDefault;
                            }}
                            src={getUrl(res?.avatar)}
                          />
                          <div className="texts">
                            <h3 style={{ color: theme()?.font.bold }}>
                              {res?.name}
                            </h3>
                            <p style={{ color: theme()?.font.regular }}>
                              {res?._id}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="container_over company">
                <div
                  style={{ borderColor: theme()?.line }}
                  className="main_title_table"
                >
                  <div className="row">
                    <BsBuildings size={"1.2em"} color={theme()?.icon.default} />
                    <h4 style={{ color: theme()?.font.bold }}>Companhias</h4>
                  </div>
                  <p
                    onClick={() => {
                      navigate("/companys");
                    }}
                    style={{ color: theme()?.main_color }}
                  >
                    Ver todas
                  </p>
                </div>
                <table>
                  <tr
                    style={{
                      backgroundColor: theme()?.container_main,
                      color: theme()?.font.bold,
                    }}
                    className="title_table"
                  >
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Data de criação</th>
                    <th>Plano</th>
                    <th>Teste</th>
                  </tr>
                  {filterCompany
                    ?.slice(0, 11)
                    ?.sort(compare)
                    .map((res: any, i: number) => {
                      return (
                        <tr
                          key={i}
                          className="other_tr"
                          style={{ borderColor: theme()?.line }}
                        >
                          <td
                            title={res?._id}
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res._id?.substring(0, 10)}...
                          </td>
                          <td
                            title={res?.name}
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            <div className="container_img">
                              <img
                                onError={(e) => {
                                  e.currentTarget.src = companyPlaceholder;
                                }}
                                src={getUrl(res?.avatar)}
                              />
                              {res.name?.substring(0, 10)}...
                            </div>
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {new Date(res.created_date).toLocaleDateString()}
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res.account_payment.account_type == "not-allowed"
                              ? "Sem plano"
                              : res.account_payment.account_type == "simple"
                              ? "Start"
                              : res.account_payment.account_type == "pro"
                              ? "Pro"
                              : res.account_payment.account_type == "premium"
                              ? "Premium"
                              : res.account_payment.account_type == "permanent"
                              ? "Permanente"
                              : ""}
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res.free_test.status == "ready"
                              ? "Pronto"
                              : res.free_test.status == "in-use"
                              ? "Em andamento"
                              : res.free_test.status == "expired"
                              ? "Expirado"
                              : ""}
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
