import { themeTypes } from "./themes.types"


export const dark_theme: themeTypes = {
    main_color: "#0072DB",
    background_color: "#0D0D0D",
    container_main: "#1F1F1F",
    container_second: "#333333",
    line: "#1B1B1B",
    line_container: "#2C2C2C",
    font: {
        bold: "#ffffff",
        regular: "#A1A1A1"
    },
    icon: {
        default: "#ffffff"
    }
}