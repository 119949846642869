import { useState } from "react"
import { theme } from "../../theme"

interface inputProps {
    text?: string,
    type?: string,
    placeholder?: string,
    display?: string,
    flexDirection?: string,
    width?: string,
    maxWidth?: string,
    margin?: string,
    color?: string,
    disable?: boolean,
    onValue?: any,
    value?: string | number
    onKey?: any

}

export default function Input({ display, type, value, flexDirection, onKey, disable, text, placeholder, width, maxWidth, margin, color, onValue }: inputProps) {
    const [texts, setTexts] = useState<string | number>()
    return (
        <div style={{
            display: display ? display : "flex",
            width: width ? width : "100%",
            maxWidth: maxWidth ? maxWidth : "100%",
            margin: margin,
            color: color,
            flexDirection: "column"
        }}>
            <p style={{
                margin: "0",
                marginBottom: "5px",
                fontSize: "14px",
                fontWeight: "400",
                color: theme()?.font.bold
            }}>{text}</p>
            <input
                style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "8px",
                    padding: "0px 10px",
                    boxSizing: "border-box",
                    border: "none",
                    outline: "none",
                    backgroundColor: theme()?.container_second,
                    color: theme()?.font.bold
                }}
                disabled={disable ? disable : false}
                value={value ? value : texts}
                placeholder={placeholder}
                onKeyDown={(e) => {
                    if(onKey) {
                        onKey(e.key)
                    }
                }}
                type={type ? type : "text"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTexts(e.target.value)
                    onValue(e.target.value)
                }} />
        </div>
    )
}