

import { dark_theme } from "./themes/dark_theme";
import { light_theme } from "./themes/light_theme";




export function theme() {
    if(localStorage.getItem("theme") == "dark_theme") {
        return dark_theme
    }
    if(localStorage.getItem("theme") == "light_theme") {
        return light_theme
    }
}