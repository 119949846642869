export const environment = {
    DB_CONNECT: "https://ranking-web-api.vercel.app",
    DB_RANKING: "https://api.rankingdevendas.com.br",
    // DB_RANKING: "http://localhost:4001",
    GET_IMAGE: "https://api.rankingdevendas.com.br/uploads",
    FRONT_URL: "http://localhost:3000",
    // FRONT_URL: "http://localhost:3000"
};


export const environmentTheme = {
    THEME: "dark_theme"
}


