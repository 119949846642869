import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

//Pages
import Company from "../pages/companys/companys";
import Dashboard from "../pages/dashboard/dashboard";
import Login from "../pages/login/login";
import Notification from "../pages/notification/notification";
import User from "../pages/users/users";

export default function Router() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={"/dashboard"} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/companys" element={<Company />} />
          <Route path="/users" element={<User />} />
          <Route path="/notifications" element={<Notification />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
