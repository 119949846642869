import { themeTypes } from "./themes.types"


export const light_theme: themeTypes = {
    main_color: "#0072DB",
    background_color: "#fff",
    container_main: "#f1f1f1",
    container_second: "#e6e6e6",
    line: "#EDEDED",
    line_container: "#E2E2E2",
    font: {
        bold: "#181818",
        regular: "#6C6C6C"
    },
    icon: {
        default: "#181818"
    }
}