import axios from "axios";

//data
import { environment } from "../data/environment";

export async function getCompanys() {
    const token = localStorage.getItem("@WR:Token")
    if(!token) {
        return window.location.href = "/login"
    }
    const _get = await axios.get(`${environment.DB_RANKING}/company/all` , {
        headers: {
            "x-access-token": token
        }
    });
    if(!_get.data) {
        return "Erro ao processar"
    };
    return _get.data
}
export async function getUsers() {
    const token = localStorage.getItem("@WR:Token")
    const _get = await axios.get(`${environment.DB_RANKING}/users` , {
        headers: {
            "x-access-token": token
        }
    });
    if(!_get.data) {
        return "Erro ao processar"
    };
    return _get.data
}
export async function getUser({id}:{id?:string}) {
    const token = localStorage.getItem("@WR:Token")
    const _get = await axios.get(`${environment.DB_RANKING}/users/${id}` , {
        headers: {
            "x-access-token": token
        }
    });
    if(!_get.data) {
        return "Erro ao processar"
    };
    return _get.data
}
export async function getBlog() {
    const BLtoken = localStorage.getItem("@BL:Token")
    const _get = await axios.get(`${environment.DB_CONNECT}/blog/all` , {
        headers: {
            token: BLtoken
        }
    });
    if(_get) {
        return _get.data
    }
    return "Erro ao processar"

}
export async function getPost({id} : {id?:string}) {
    const BLtoken = localStorage.getItem("@BL:Token")
    const _get = await axios.get(`${environment.DB_CONNECT}/blog/${id}` , {
        headers: {
            token: BLtoken
        }
    });
    if(_get) {
        return _get.data
    }
    return "Erro ao processar"

}
export async function getAllcompany({id} : {id?:string}) {
    const token = localStorage.getItem("@WR:Token")
    const _get = await axios.get(`${environment.DB_RANKING}/company/alluser/${id}` , {
        headers: {
            "x-access-token": token
        }
    });
    if(_get) {
        return _get.data
    }
    return "Erro ao processar"

}
export async function getCoupons() {
    const token = localStorage.getItem("@WR:Token")
    const _get = await axios.get(`${environment.DB_RANKING}/coupon/all` , {
        headers: {
            "x-access-token": token
        }
    });
    if(!_get.data) {
        return "Erro ao processar"
    };
    return _get.data
}
export async function getNotifications() {
    const token = localStorage.getItem("@WR:Token")
    const _get = await axios.get(`${environment.DB_RANKING}/v1/notification` , {
        headers: {
            "x-access-token": token
        }
    });
    if(!_get.data) {
        return "Erro ao processar"
    };
    return _get.data
}