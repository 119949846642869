import { Toaster } from "react-hot-toast";
import Router from "./routes/router";

//Functions
import Redirect from "./functions/redirect";

//Style
import { useEffect } from "react";
import "../src/global.css";
import { theme } from "./theme";

function App() {
  useEffect(() => {
    if(!localStorage.getItem("theme")) {
      localStorage.setItem("theme" , "dark_theme")
    }
  } , [])
  Redirect()
  return (
    <div className="App">
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            background: theme()?.container_main,
            color: theme()?.font.bold,
            zIndex: "9999999",
            borderRadius: "100px"
          },
        }}
      />
      <Router />
    </div>
  );
}

export default App;
