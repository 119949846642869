//Defaults
import { useEffect, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { toast } from "react-hot-toast";

//Data
import { getLastDays } from "../../functions/getDate";
import { month_data } from "../dashboard/data/data_month";

//Services
import { compare } from "../../functions/compare";
import { getAllcompany, getCompanys } from "../../services/get";

//Components
import Shimmer from "../../components/shimmer/shimmer";
import Sidebar from "../../components/sidebar/sidebar";

//Image
import companyPlaceholder from "../../img/company_placeholder.png";
import userDefault from "../../img/default_img.jpg";

//Icons
import {
  AiFillCrown,
  AiOutlineClose,
  AiOutlineCrown,
  AiOutlineUser,
} from "react-icons/ai";
import { BsBuildings } from "react-icons/bs";

//Style
import {
  MdCalendarMonth,
  MdDone,
  MdDownload,
  MdEmail,
  MdMoneyOff,
  MdSearch
} from "react-icons/md";
import Button from "../../components/button/buttons";
import Input from "../../components/input/input";
import Popup from "../../components/popups/popups";
import { getUrl } from "../../functions/getUrl";
import { formatPhoneNumber } from "../../functions/phoneFormat";
import useLocalStorage from "../../hooks/useLocalStorage";
import { deleteCompany } from "../../services/delete";
import { editNumberTeam } from "../../services/post";
import { editPlans } from "../../services/put";
import { theme } from "../../theme";
import "./companys.css";

function Details({
  id,
  company,
  setPopUp,
  setReload,
  setSearch,
}: {
  id?: string;
  setSearch?: any;
  company?: any;
  setPopUp?: any;
  setReload: any;
}) {
  const [employees, setEmployees] = useState<any>();
  const [subPopup, setSubpopup] = useState<string>("");
  const [teamValue, setTeamValue] = useState<number>(
    company?.account_payment?.team_number
  );

  const [plans, setPlans] = useState<[] | any>([
    {
      id: "not-allowed",
      name: "Sem plano",
    },
    {
      id: "simple",
      name: "Start",
    },
    {
      id: "pro",
      name: "Pro",
    },
    {
      id: "premium",
      name: "Premium",
    },
    {
      id: "permanent",
      name: "Permanente",
    },
  ]);
  useEffect(() => {
    async function fetchData() {
      const _get = await getAllcompany({ id: company.employees[0] });
      setEmployees(_get.employees);
    }
    fetchData();
  }, []);
  async function planEdit(value: string) {
    setReload(true);
    const toastId = toast.loading("Editando plano...");
    const _edit = await editPlans({ id: company?._id, value: value });
    if (!_edit.code || _edit.code != 200) {
      return toast.error("Erro ao deletar companhia!");
    }
    toast.dismiss(toastId);
    toast.success("Plano editodo com sucesso!");
    return setReload(false);
  }
  async function companyDelete() {
    setReload(true);
    const _delete = await deleteCompany({ id: company._id });

    if (!_delete.code || _delete.code != 200) {
      return toast.error("Erro ao deletar companhia!");
    }
    setPopUp("");
    setSubpopup("");
    setSearch("");
    toast.success("Companhia deletada com sucesso!");
    return setReload(false);
  }
  async function teamNumberEdit() {
    const toastId = toast.loading("Editando companhia...");
    const _edit = await editNumberTeam({ id: company?._id, value: teamValue });
    if (_edit?.code != 200 || !_edit?.code) {
      toast.dismiss(toastId);
      return toast.error("Erro ao editar time!");
    }
    toast.dismiss(toastId);
    setReload(true);
    return toast.success("Time editado com sucesso!");
  }

  return (
    <div className="background_company">
      {subPopup == "CONFIRM" && (
        <Popup
          title="Deletar companhia"
          subTitle="Você tem certeza que realmente deseja deletar essa companhia?"
          textOne="Cancelar"
          textTwo="Confirmar"
          TwoButton={() => {
            companyDelete();
          }}
          OneButton={() => {
            setSubpopup("");
          }}
        />
      )}
      <div
        className="container-details"
        style={{
          backgroundColor: theme()?.background_color,
        }}
      >
        <div className="top">
          <AiOutlineClose
            color={theme()?.icon.default}
            size={"1.2em"}
            onClick={() => {
              setPopUp("");
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="first-content">
          <div className="name_container">
            <img
              onError={(e) => {
                e.currentTarget.src = companyPlaceholder;
              }}
              src={getUrl(company?.avatar)}
              style={{
                backgroundColor: theme()?.container_main,
              }}
            />
            <h1 style={{ color: theme()?.font.bold }}>{company?.name}</h1>
          </div>
          <div
            className="line"
            style={{ backgroundColor: theme()?.line }}
          ></div>
          <div className="info-row">
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>ID:</span>{" "}
              {company._id}
            </p>
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>E-mail:</span>{" "}
              {company?.main_email}
            </p>
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>Telefone:</span>{" "}
              {formatPhoneNumber(company?.phone_number)}
            </p>
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>
                Data de criação:
              </span>{" "}
              {new Date(company?.created_date).toDateString()}
            </p>
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>Contrato:</span>{" "}
              {company.account_payment.account_type == "not-allowed"
                ? "Sem plano"
                : company.account_payment.account_type == "simple"
                ? "Start"
                : company.account_payment.account_type == "pro"
                ? "Pro"
                : company.account_payment.account_type == "premium"
                ? "Premium"
                : company.account_payment.account_type == "permanent"
                ? "Permanente"
                : ""}
            </p>
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>
                Teste gratuito:
              </span>{" "}
              {company.free_test.status == "ready"
                ? "Pronto"
                : company.free_test.status == "in-use"
                ? "Em andamento"
                : company.free_test.status == "expired"
                ? "Expirado"
                : ""}
            </p>
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>CNPJ:</span>{" "}
              {company.cnpj}
            </p>
            <p
              style={{
                backgroundColor: theme()?.container_main,
                color: theme()?.font.bold,
              }}
            >
              <span style={{ color: theme()?.font.regular }}>
                Funcionários:
              </span>{" "}
              {company.employees.length}
            </p>
          </div>
          <div
            className="line"
            style={{ backgroundColor: theme()?.line }}
          ></div>
          <div className="plans_select">
            {company?.account_payment?.account_type != "simple" && (
              <div
                className="input_team"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "35px",
                  gridGap: "5px",
                }}
              >
                <p
                  style={{
                    color: theme()?.font.regular,
                    whiteSpace: "nowrap",
                    marginRight: "5px",
                  }}
                >
                  Times:{" "}
                </p>
                <Input
                  type="number"
                  onValue={(value: any) => {
                    setTeamValue(value);
                  }}
                  onKey={() => {}}
                  value={teamValue}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50px",
                    height: "37px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    backgroundColor: theme()?.container_main,
                  }}
                  onClick={() => {
                    teamNumberEdit();
                  }}
                >
                  <MdDone size={"1.2em"} color={theme()?.main_color} />
                </div>
              </div>
            )}
            <p style={{ color: theme()?.font.regular }}>Tipo de Plano: </p>
            {plans?.map((res: any, i: number) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    planEdit(res?.id);
                  }}
                  style={{
                    color:
                      company?.account_payment?.account_type == res?.id
                        ? "white"
                        : theme()?.font.regular,
                    backgroundColor:
                      company?.account_payment?.account_type == res?.id
                        ? theme()?.main_color
                        : theme()?.container_main,
                  }}
                  className="plan"
                >
                  <AiFillCrown size={"1.3em"} />
                  {res.name}
                </div>
              );
            })}
          </div>
          <div
            className="line"
            style={{ backgroundColor: theme()?.line }}
          ></div>
          <div className="table_lim">
            <table>
              <tr
                style={{
                  backgroundColor: theme()?.container_main,
                  color: theme()?.font.bold,
                }}
                className="title_table"
              >
                <th>Nome</th>
                <th>E-mail</th>
                <th>Acesso</th>
                <th>N°Times</th>
                <th>Último Login</th>
                <th>Data de criação</th>
              </tr>
              {employees?.sort(compare).map((res: any, i: number) => {
                return (
                  <tr className="other_tr" key={i}>
                    <td
                      style={{
                        borderColor: theme()?.line,
                        color: theme()?.font.regular,
                      }}
                    >
                      <div className="container_img">
                        <img
                          onError={(e) => {
                            e.currentTarget.src = userDefault;
                          }}
                          src={getUrl(res?.avatar)}
                        />
                        {res.name}
                      </div>
                    </td>
                    <td
                      style={{
                        borderColor: theme()?.line,
                        color: theme()?.font.regular,
                      }}
                      title={res?.email}
                    >
                      {res?.email?.substring(0, 35)}
                      {res?.email?.length >= 35 && "..."}
                    </td>
                    <td
                      style={{
                        borderColor: theme()?.line,
                        color: theme()?.font.regular,
                      }}
                    >
                      {res.admin == 0
                        ? "Funcionário"
                        : res.admin == 1
                        ? "Adminstrador(Com vizualização)"
                        : res.admin == 2
                        ? "Adminstrador(Com vizualização)"
                        : res.admin == 3
                        ? "Vizualizador"
                        : res.admin == 5
                        ? "Funcionário(Sem edição)"
                        : "Painel"}
                    </td>
                    <td
                      style={{
                        borderColor: theme()?.line,
                        color: theme()?.font.regular,
                      }}
                    >
                      {res?.teams?.length}
                    </td>
                    <td
                      style={{
                        borderColor: theme()?.line,
                        color: theme()?.font.regular,
                      }}
                    >
                      {res?.last_login ? (
                        <>
                          {new Date(res.last_login).toLocaleDateString()} ás{" "}
                          {new Date(res.last_login).toLocaleTimeString()}
                        </>
                      ) : (
                        "Esperando login..."
                      )}
                    </td>
                    <td
                      style={{
                        borderColor: theme()?.line,
                        color: theme()?.font.regular,
                      }}
                    >
                      {new Date(res?.created_date).toLocaleDateString()}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          <div className="action-buttons">
            <Button
              text="Deletar companhia"
              backcolor="red"
              onTap={() => {
                setSubpopup("CONFIRM");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Company() {
  const [initialMenu, setInitialMenu] = useState<[] | any>();
  const [companys, setCompanys] = useState<[] | any>([]);
  const [filterCompany, setFilterCompany] = useState<[] | any>([]);
  const [paymentCompanys, setPaymentCompanys] = useState<Number>(0);
  const [dates, setDates] = useState<[] | any>([]);
  const [pack, setPack] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchType, setSearchType] = useState<string>("name");
  const [usetheme, setUsetheme] = useLocalStorage(
    "theme",
    window.localStorage.getItem("theme")
  );
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState<boolean>(false);
  const [simple, setSimple] = useState<number>(0);
  const [pro, setPro] = useState<number>(0);
  const [permanent, setPermanent] = useState<number>(0);
  const [premium , setPremium] = useState<number>(0)
  const [index, setIndex] = useState<number>(0);
  const [popup, setPopUp] = useState<string>("");
  const [state, setState] = useState<string>("COMPANY");
  const [tableRef, setTableRef] = useState<any>(null);

  const thSyle2: any = {
    fontSize: "14px",
    color: "#232323",
    verticalAlign: "middle",
    textAlign: "start",
    borderTop: "1px solid rgba(255, 255, 255, 0.158)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.158)",
    padding: "10px 10px",
  };
  const tdStyle2: any = {
    fontSize: "14px",
    fontWeight: "400",
    color: "#232323",
    verticalAlign: "middle",
    textAlign: "start",
    borderTop: "1px solid rgba(255, 255, 255, 0.158)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.158)",
    padding: "10px 10px",
  };

  useEffect(() => {
    async function fetchData() {
      const [companysarr] = await Promise.all([getCompanys()]);
      setCompanys(companysarr.data);

      setSimple(
        companysarr?.data?.filter(
          (res: any) => res?.account_payment.account_type == "simple"
        ).length
      );
      setPro(
        companysarr?.data?.filter(
          (res: any) => res?.account_payment.account_type == "pro"
        ).length
      );
      setPremium(
        companysarr?.data?.filter(
          (res: any) => res?.account_payment.account_type == "premium"
        ).length
      );
      setPermanent(
        companysarr?.data?.filter(
          (res: any) => res?.account_payment.account_type == "permanent"
        ).length
      );
      setDates(
        companysarr?.data
          ?.sort(compare)
          .map((res: any) => new Date(res.created_date).toDateString())
      );
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    fetchData();
  }, [reload]);
  useEffect(() => {
    if (pack != null) {
      setDates(
        companys
          ?.sort(compare)
          .filter((res: any) => res?.account_payment?.account_type == pack)
          .map((res: any) => new Date(res.created_date).toDateString())
      );
      setFilterCompany(
        companys.filter(
          (res: any) =>
            res?.[searchType]?.toLowerCase().includes(search?.toLowerCase()) &&
            res?.account_payment?.account_type == pack
        )
      );
    }
    if (pack == null) {
      setDates(
        companys
          ?.sort(compare)
          .map((res: any) => new Date(res.created_date).toDateString())
      );
      setFilterCompany(
        companys.filter((res: any) =>
          res?.[searchType]?.toLowerCase().includes(search?.toLowerCase())
        )
      );
    }
  }, [pack, search, companys, searchType]);

  useEffect(() => {
    setInitialMenu([
      {
        title: "Cadastradas",
        icon: <BsBuildings size={"2em"} color={theme()?.icon.default} />,
        value: companys.length,
        percent: false,
      },
      {
        title: "Pagantes",
        icon: <MdMoneyOff size={"2em"} color={theme()?.icon.default} />,
        value: pro + simple + premium,
        percent: true,
      },
      {
        title: "Planos Simples",
        icon: <AiOutlineCrown size={"2em"} color={theme()?.icon.default} />,
        value: simple,
        percent: true,
      },
      {
        title: "Planos Pro",
        icon: <AiOutlineCrown size={"2em"} color={theme()?.icon.default} />,
        value: pro,
        percent: true,
      },
      {
        title: "Planos Premium",
        icon: <AiOutlineCrown size={"2em"} color={theme()?.icon.default} />,
        value: premium,
        percent: true,
      },
    ]);
  }, [companys, paymentCompanys, usetheme]);
  return (
    <div
      className="company"
      style={{ backgroundColor: theme()?.background_color }}
    >
      <Sidebar
        id="companys"
        light={() => {
          setUsetheme("light_theme");
        }}
        dark={() => {
          setUsetheme("dark_theme");
        }}
      />
      {popup == "DETAILS" && (
        <Details
          setSearch={setSearch}
          setPopUp={setPopUp}
          company={companys?.find((res: any) => res?._id == index)}
          setReload={setReload}
        />
      )}
      <div className="content_dashboard">
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div className="p1_title">
          <div className="texts">
            <h1 className="title" style={{ color: theme()?.font.bold }}>
              Companhias
            </h1>
            <p style={{ color: theme()?.font.regular }}>
              Companhias cadastradas no ranking de vendas.
            </p>
          </div>
          <div className="resumes">
            {loading && (
              <>
                <Shimmer width="160px" height="70px" radius="15px" />
                <Shimmer width="160px" height="70px" radius="15px" />
                <Shimmer width="160px" height="70px" radius="15px" />
                <Shimmer width="160px" height="70px" radius="15px" />
                <Shimmer width="160px" height="70px" radius="15px" />
              </>
            )}
            {!loading && (
              <>
                {initialMenu?.map((res: any, i: number) => {
                  return (
                    <div key={i} className="item_menu">
                      <div
                        style={{ backgroundColor: theme()?.container_main }}
                        className="icon_container"
                      >
                        {res?.icon}
                      </div>
                      <div className="texts">
                        <p style={{ color: theme()?.font.regular }}>
                          {res?.title}
                        </p>
                        <h1 style={{ color: theme()?.font.bold }}>
                          {res?.value}
                        </h1>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="line" style={{ backgroundColor: theme()?.line }}></div>
        <div
          style={{ backgroundColor: theme()?.container_main }}
          className="warning_bar"
        >
          <div className="p1_warnign">
            <div
              style={{ backgroundColor: theme()?.container_second }}
              className="icon_container"
            >
              <MdCalendarMonth size={"1.2EM"} color={theme()?.icon.default} />
            </div>
            <div className="texts">
              <h3 style={{ color: theme()?.font.bold }}>
                Visão de todos os dias
              </h3>
              <p style={{ color: theme()?.font.regular }}>
                Hoje - {new Date().toDateString()}
              </p>
            </div>
            <div
              className="vline"
              style={{ borderColor: theme()?.line_container }}
            ></div>
            <div className="container_buttons">
              <div
                onClick={() => {
                  setState("COMPANY");
                }}
                style={{
                  backgroundColor:
                    state == "COMPANY"
                      ? theme()?.main_color
                      : theme()?.background_color,
                  color: state == "COMPANY" ? "white" : theme()?.font.regular,
                }}
                className="button"
              >
                Companhias
              </div>
              <div
                onClick={() => {
                  setState("CREATE");
                }}
                style={{
                  backgroundColor:
                    state == "CREATE"
                      ? theme()?.main_color
                      : theme()?.background_color,
                  color: state == "CREATE" ? "white" : theme()?.font.regular,
                }}
                className="button"
              >
                Calendário de criação
              </div>
            </div>
          </div>
          <div className="buttons">
            <DownloadTableExcel
              filename={`Relatórios | ${new Date().toLocaleDateString()} (${
                pack == null
                  ? "Todos"
                  : pack == "not-allowed"
                  ? "Sem plano"
                  : pack == "simple"
                  ? "Start"
                  : pack == "pro"
                  ? "Pro"
                  : pack == "premium"
                  ? "Premium"
                  : pack == "permanent"
                  ? "Permanente"
                  : ""
              })`}
              sheet="reports"
              currentTableRef={tableRef}
            >
              <div
                style={{ backgroundColor: theme()?.main_color, height: "60px" }}
                className="download_button"
              >
                Baixar relatório
                <MdDownload />
              </div>
            </DownloadTableExcel>
          </div>
        </div>
        <div className="filter_bar" style={{ borderColor: theme()?.line }}>
          <p style={{ color: theme()?.font.bold }}>Filtros: </p>
          <div className="filters">
            <div className="filter_container">
              <p
                style={{
                  color: theme()?.font.regular,
                }}
              >
                Tipo de plano:{" "}
              </p>
              <select
                onChange={(e) => {
                  if (e.target.value == "Todos") {
                    return setPack(null);
                  }
                  if (e.target.value == "Sem plano")
                    return setPack("not-allowed");
                  if (e.target.value == "Start") return setPack("simple");
                  if (e.target.value == "Pro") return setPack("pro");
                  if (e.target.value == "Premium") return setPack("premium");
                  if (e.target.value == "Permanente")
                    return setPack("permanent");
                }}
                style={{
                  color: theme()?.font.regular,
                  borderColor: theme()?.line,
                }}
                defaultValue={"Todos"}
              >
                <option style={{ backgroundColor: theme()?.container_main }}>
                  Todos
                </option>
                <option style={{ backgroundColor: theme()?.container_main }}>
                  Sem plano
                </option>
                <option style={{ backgroundColor: theme()?.container_main }}>
                  Start
                </option>
                <option style={{ backgroundColor: theme()?.container_main }}>
                  Pro
                </option>
                <option style={{ backgroundColor: theme()?.container_main }}>
                  Premium
                </option>
                <option style={{ backgroundColor: theme()?.container_main }}>
                  Permanente
                </option>
              </select>
            </div>
            <div
              style={{
                borderColor: theme()?.line,
                backgroundColor: "transparent",
              }}
              className="input_search"
            >
              <input
                style={{
                  color: theme()?.font.bold,
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder={`Pesquisar por ${
                  { name: "nome", main_email: "email" }[searchType]
                }`}
                value={search}
              />
              <MdSearch color={theme()?.icon.default} size={"1.1em"} />
            </div>
            <div className="select_search">
              <div
                className="container_search"
                onClick={() => {
                  setSearchType("name");
                }}
                style={{
                  backgroundColor:
                    searchType == "name"
                      ? theme()?.main_color
                      : theme()?.container_main,
                }}
              >
                <AiOutlineUser color={theme()?.icon?.default} />
              </div>
              <div
                style={{
                  backgroundColor:
                    searchType == "main_email"
                      ? theme()?.main_color
                      : theme()?.container_main,
                }}
                onClick={() => {
                  setSearchType("main_email");
                }}
                className="container_search"
              >
                <MdEmail color={theme()?.icon?.default} />
              </div>
            </div>
          </div>
        </div>
        <div className="p1_content">
          {loading && (
            <>
              <Shimmer width="100%" height="100%" radius="25px" />
            </>
          )}
          {!loading && (
            <>
              {state == "CREATE" && (
                <div
                  className="container_over flow"
                  style={{ backgroundColor: theme()?.container_main }}
                >
                  <div
                    style={{ borderColor: theme()?.line_container }}
                    className="main_title_table"
                  >
                    <div className="row">
                      <MdCalendarMonth
                        size={"1.2em"}
                        color={theme()?.icon.default}
                      />
                      <h4 style={{ color: theme()?.font.bold }}>
                        Calendário de criação (1 Ano)
                      </h4>
                    </div>
                  </div>
                  <div className="list_flow">
                    {getLastDays(367).map((res: any, i: number) => {
                      const date = new Date(res).toDateString();
                      const month = new Date(res).getMonth();
                      const day = new Date(res).getDate();
                      const unit = dates?.filter(
                        (res: any) => res == date
                      )?.length;
                      return (
                        <div
                          key={i}
                          title={`${unit} Companhias`}
                          style={{ backgroundColor: theme()?.container_second }}
                          className="date_button"
                        >
                          <p style={{ color: theme()?.font.regular }}>
                            {month_data[month]}/{day}
                          </p>
                          <div
                            className="dots"
                            style={{
                              backgroundColor: dates?.includes(date)
                                ? theme()?.main_color
                                : theme()?.container_main,
                            }}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {state == "COMPANY" && (
                <div className="container_over company">
                  <div
                    style={{ borderColor: theme()?.line }}
                    className="main_title_table"
                  >
                    <div className="row">
                      <BsBuildings
                        size={"1.2em"}
                        color={theme()?.icon.default}
                      />
                      <h4 style={{ color: theme()?.font.bold }}>
                        Companhias({companys?.length})
                      </h4>
                    </div>
                  </div>
                  <table>
                    <tr
                      style={{
                        backgroundColor: theme()?.container_main,
                        color: theme()?.font.bold,
                      }}
                      className="title_table"
                    >
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Data de criação</th>
                      <th>N°Funcionários</th>
                      <th>N°Times</th>
                      <th>Plano</th>
                      <th>Teste</th>
                      <th></th>
                    </tr>
                    {filterCompany?.sort(compare).map((res: any, i: number) => {
                      return (
                        <tr
                          key={i}
                          className="other_tr"
                          style={{ borderColor: theme()?.line }}
                        >
                          <td
                            title={res?._id}
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res._id}
                          </td>
                          <td
                            title={res?.name}
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            <div className="container_img">
                              <img
                                onError={(e) => {
                                  e.currentTarget.src = companyPlaceholder;
                                }}
                                src={getUrl(res?.avatar)}
                              />
                              {res.name}
                            </div>
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {new Date(res.created_date).toLocaleDateString()}
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res?.employees?.length}
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res?.teams?.length}
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res.account_payment.account_type == "not-allowed"
                              ? "Sem plano"
                              : res.account_payment.account_type == "simple"
                              ? "Start"
                              : res.account_payment.account_type == "pro"
                              ? "Pro"
                              : res.account_payment.account_type == "premium"
                              ? "Premium"
                              : res.account_payment.account_type == "permanent"
                              ? "Permanente"
                              : ""}
                          </td>
                          <td
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.font.regular,
                            }}
                          >
                            {res.free_test.status == "ready"
                              ? "Pronto"
                              : res.free_test.status == "in-use"
                              ? "Em andamento"
                              : res.free_test.status == "expired"
                              ? "Expirado"
                              : ""}
                          </td>
                          <td
                            onClick={() => {
                              setIndex(res?._id);
                              setPopUp("DETAILS");
                            }}
                            style={{
                              borderColor: theme()?.line,
                              color: theme()?.main_color,
                              cursor: "pointer",
                            }}
                          >
                            Mais detalhes
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              )}
              <table
                ref={(el) => {
                  setTableRef(el);
                }}
                style={{
                  width: "100%",
                  borderSpacing: "0",
                  display: "none",
                }}
              >
                <tr>
                  <th style={thSyle2}>ID</th>
                  <th style={thSyle2}>Nome</th>
                  <th style={thSyle2}>Data de criação</th>
                  <th style={thSyle2}>Email</th>
                  <th style={thSyle2}>N°Telefone</th>
                  <th style={thSyle2}>N°Funcionários</th>
                  <th style={thSyle2}>N°Times</th>
                  <th style={thSyle2}>Plano</th>
                  <th style={thSyle2}>Teste</th>
                </tr>
                {filterCompany?.map((res: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td style={tdStyle2}>{res?._id}</td>
                      <td style={tdStyle2}>{res?.name}</td>
                      <td style={tdStyle2}>
                        {new Date(res.created_date).toLocaleDateString()}
                      </td>
                      <td style={tdStyle2}>{res?.main_email}</td>
                      <td style={tdStyle2}>
                        {formatPhoneNumber(res?.phone_number)}
                      </td>
                      <td style={tdStyle2}>{res?.employees?.length}</td>
                      <td style={tdStyle2}>{res?.teams?.length}</td>
                      <td style={tdStyle2}>
                        {res.account_payment.account_type == "not-allowed"
                          ? "Sem plano"
                          : res.account_payment.account_type == "simple"
                          ? "Start"
                          : res.account_payment.account_type == "pro"
                          ? "Pro"
                          : res.account_payment.account_type == "premium"
                          ? "Premium"
                          : ""}
                      </td>
                      <td style={tdStyle2}>
                        {res.free_test.status == "ready"
                          ? "Pronto"
                          : res.free_test.status == "in-use"
                          ? "Em andamento"
                          : res.free_test.status == "expired"
                          ? "Expirado"
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
